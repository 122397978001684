<template>
  <PatientPage :patient-no="patientNo" page-title="Blood glucose values">
    <template #sub-header>
      <DoseTypeInfo :patient="patient" :isLoading="isLoading" />
    </template>

    <v-row>
      <v-col>
        <div v-if="isLoading" class="py-10 text-center">
          <v-progress-circular :size="50" color="grey" indeterminate />
        </div>

        <SmbgList v-if="showSmbgList" :patient-no="patientNo" />

        <NotEnabled v-if="showNotEnabled" :patient-no="patientNo" />
      </v-col>
    </v-row>
  </PatientPage>
</template>

<script>
import PatientPage from '@/components/patient/PatientPage'
import SmbgList from '@/components/blood-glucose-measurements/self-monitoring/SmbgList'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import selectedPatientGettersMixin from '@/components/mixins/store/selectedPatientGettersMixin'
import loadingState from '@/constants/loadingState'
import DoseTypeInfo from '@/components/DoseTypeInfo'
import service from '../../services/patient-service'
import Vue from 'vue'

export default {
  name: 'SmbgData',
  mixins: [trialSettingsGettersMixin, selectedPatientGettersMixin],
  components: {
    DoseTypeInfo,
    NotEnabled: () => import('@/components/layout/NotEnabled'),
    PatientPage,
    SmbgList,
  },
  props: {
    patientNo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      state: loadingState.INITIAL,
      patient: null,
    }
  },
  computed: {
    bgmsEnabled() {
      return this.trialFeatureFlags(this.patientNo).bgmsEnabled
    },
    isLoading() {
      return (
        this.selectedPatientConfigurationLoadingState ===
          loadingState.LOADING ||
        this.selectedPatientConfigurationLoadingState === loadingState.INITIAL
      )
    },
    showSmbgList() {
      return !this.isLoading && this.bgmsEnabled
    },
    showNotEnabled() {
      return !this.isLoading && !this.bgmsEnabled
    },
  },
  methods: {
    loadPatient() {
      this.state = loadingState.LOADING
      service
        .getPatient(this.patientNo)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.patient = response.data
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          Vue.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
  created() {
    this.loadPatient()
  },
}
</script>
